import * as types from "./types";

export const initializeContador = (payload) => ({
    type: types.HYQ3_INIT_COMPONENT, payload
})

export function modifyInputs(reducer, path, value) {
    return (dispatch) => {
        const type = types.HYQ3_MODIFY_INPUT_VALUE
        dispatch({ type, payload: { reducer, path, value } })
    }
}