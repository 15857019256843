import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

// Componentes externos
import Draggable from "react-draggable";

// Utilidades
import {
    crearContenedorEnBody,
    eliminarContenedorDelBody
} from "../helpers";

// Estilos
import bootstrap from 'bootstrap/dist/css/bootstrap.min.css';
import css from '../styles.css';

const Modal = (props) => {
    let {
        alto,
        ancho,
        bordes,
        botones,
        contenido,
        maximizable,
        mostrar,
        nombre,
        titulo,
        onCerrarModal,
        maximized
    } = props;

    if (typeof alto !== 'string') {
        alto = 'auto';
    }

    if (typeof ancho !== 'string') {
        ancho = 'auto';
    }

    if (typeof bordes !== 'boolean') {
        bordes = true;
    }

    if (typeof maximizable !== 'boolean') {
        maximizable = false;
    }

    if (typeof mostrar !== 'boolean') {
        mostrar = false;
    }

    if (typeof nombre !== 'string') {
        nombre = 'modalTest';
    }

    if (typeof titulo !== 'string') {
        titulo = '';
    }

    if (typeof maximized !== 'boolean') {
        maximized = false;
    }

    // Hooks

    const [maximizado, setMaximizado] = useState(maximized);
    const [estilosModalBody, setEstilosModalBody] = useState({});

    useEffect(() => {
        return () => {
            eliminarContenedorDelBody(nombre);
        }
    }, []);

    useEffect(() => {
        if (mostrar) {
            crearContenedorEnBody(nombre);
        } else {
            eliminarContenedorDelBody(nombre);
        }
    }, [mostrar])

    // Controlar la altura del body cuando esta maximizado
    useEffect(() => {
        if (maximizado) {
            const elemento_dinamico = document.getElementById(nombre);
            const altura_ventana = window.innerHeight;
            const altura_modal_header = elemento_dinamico.getElementsByClassName(bootstrap["modal-header"])[0].clientHeight;
            const altura_modal_footer = elemento_dinamico.getElementsByClassName(bootstrap["modal-footer"])[0].clientHeight;

            setEstilosModalBody({
                height: altura_ventana - (altura_modal_header + altura_modal_footer)
            });
        } else {
            setEstilosModalBody({});
        }
    }, [maximizado]);

    // Eventos

    /**
     * Cuando el usuario hace click en el botón de cerrar el Modal
     * @param {*} event 
     */
    const evento_onCerrarModal = (event) => {
        event.preventDefault();

        if (maximizable) {
            setMaximizado(false);
        }

        if (typeof onCerrarModal !== 'function') {
            console.log('Implemente la propiedad onCerrarModal para realizar una acción al cerrar el modal')
        } else {
            onCerrarModal();
        }
    }

    /**
     * Cuando el usuario hace click en el botón de maximizar
     * @param {*} event 
     */
    const evento_onClickMaximizar = (event) => {
        event.preventDefault();

        setMaximizado(!maximizado);

        if (typeof onMazimixarModal !== 'function') {
            console.log('Implemente la propiedad onMazimixarModal para realizar una acción al maximizar el modal')
        } else {
            onMazimixarModal(!maximizado);
        }
    }

    // Render

    let clases_modal = [
        bootstrap.modal,
        bootstrap.fade,
        (mostrar ? bootstrap.in : ''),
        css.modal,
        (maximizado ? css.maximizado : undefined),
        'mws-modal'
    ].join(' ');

    let clases_modal_dialog = [
        bootstrap["modal-dialog"],
        css['modal-dialog'],
        'mws-modal-dialog'
    ].join(' ');

    let clases_modal_content = [
        bootstrap["modal-content"],
        css['modal-content'],
        !bordes ? css["modal-content-sin-bordes"] : undefined,
        'mws-modal-content'
    ].join(' ');

    let clases_modal_header = [
        bootstrap["modal-header"],
        css['modal-header'],
        'mws-modal-header'
    ].join(' ');

    let clases_boton_cerrar = [
        bootstrap["btn"],
        bootstrap['btn-link'],
        bootstrap['btn-xs'],
        css['header-button'],
    ].join(' ');

    let clases_span_boton_cerrar = [
        bootstrap['glyphicon'],
        bootstrap['glyphicon-remove'],
        'btn-cerrar'
    ].join(' ');

    let clases_boton_maximizar = [
        bootstrap["btn"],
        bootstrap['btn-link'],
        bootstrap['btn-xs'],
        css['header-button'],
        'btn-maximizar'
    ].join(' ');

    let clases_span_boton_maximizar = [
        bootstrap['glyphicon'],
        (!maximizado ? bootstrap['glyphicon-resize-full'] : undefined),
        (maximizado ? bootstrap['glyphicon-resize-small'] : undefined),
    ].join(' ');

    let clases_modal_titulo = [
        bootstrap["modal-title"],
        'mws-modal-title'
    ].join(' ');

    let clases_modal_body = [
        bootstrap["modal-body"],
        css['modal-body'],
        'mws-modal-body'
    ].join(' ');

    let clases_modal_footer = [
        bootstrap["modal-footer"],
        'mws-modal-footer'
    ].join(' ');

    let clases_modal_boton_defecto_cerrar = [
        bootstrap.btn,
        bootstrap["btn-default"]
    ].join(' ');

    let modal_styles = {
        display: 'flex'
    };

    let modal_dialog_styles = {};
    let modal_content_styles = {};

    /*
        Añadimos alto y ancho predefinidos siempre y cuando el modal no sea 
        maximizable
    */
    if (!maximizable && (alto !== null && ancho != null)) {
        modal_dialog_styles = {
            ...modal_dialog_styles,
            height: alto,
            width: ancho
        };

        modal_content_styles = {
            ...modal_content_styles,
            minWidth: 'unset'
        };
    }

    if (!mostrar) {
        return null;
    }

    crearContenedorEnBody(nombre);

    return ReactDOM.createPortal(
        <div aria-hidden="true" className={clases_modal} role="dialog" tabIndex="-1" style={modal_styles}>
            <div className={clases_modal_dialog} role="document" style={modal_dialog_styles}>
                <Draggable handle={`.${bootstrap["modal-header"]}`}>
                    <div className={clases_modal_content} style={modal_content_styles}>
                        <div className={clases_modal_header}>
                            <button
                                aria-label="Close"
                                className={clases_boton_cerrar}
                                data-dismiss="modal"
                                title="Cerrar" type="button"
                                onClick={evento_onCerrarModal}
                            >
                                <span aria-hidden="true" className={clases_span_boton_cerrar}></span>
                            </button>
                            {maximizable && (
                                <button
                                    className={clases_boton_maximizar}
                                    title={maximizado ? 'Minimizar' : 'Maximizar'}
                                    type="button"
                                    onClick={evento_onClickMaximizar}
                                >
                                    <span aria-hidden="true" className={clases_span_boton_maximizar}></span>
                                </button>
                            )}
                            <h4 className={clases_modal_titulo}>{titulo}</h4>
                        </div>
                        <div
                            className={clases_modal_body}
                            style={estilosModalBody}
                        >{contenido}</div>
                        <div className={clases_modal_footer}>
                            {botones ? botones : (
                                <button
                                    data-dismiss="modal"
                                    className={clases_modal_boton_defecto_cerrar}
                                    type="button"
                                    onClick={evento_onCerrarModal}
                                >Cerrar</button>
                            )}
                        </div>
                    </div>
                </Draggable>
            </div>
        </div >,
        document.getElementById(nombre)
    );
}

export default Modal;