import Immutable from "immutable"
import * as types from './actions/types'

const INITIAL_STATE = Immutable.fromJS({

})

export default (store, action) => {
    if (action.payload && action.payload.reducer && action.payload.path) {
        if (!action.payload.reducer || !action.payload.path) throw Error('La acción no tiene asociado reducer o reducerPath')
        const path = action.payload.path.split('.');
        switch (action.type) {
            case types.HYQ3_INIT_COMPONENT:
                store = { ...store, [action.payload.reducer]: store[action.payload.reducer].setIn(path, INITIAL_STATE) }
                break
            case types.HYQ3_MODIFY_INPUT_VALUE:
                if (!['object', 'function'].includes(typeof action.payload.value) || action.payload.value === null) {
                    store = { ...store, [action.payload.reducer]: store[action.payload.reducer].setIn(`${action.payload.path}`.split('.'), action.payload.value) }
                } else {
                    if (action.payload.immutable === false) {
                        store = { ...store, [action.payload.reducer]: store[action.payload.reducer].setIn(`${action.payload.path}`.split('.'), action.payload.value) }
                    } else {
                        store = { ...store, [action.payload.reducer]: store[action.payload.reducer].setIn(`${action.payload.path}`.split('.'), Immutable.fromJS(action.payload.value)) }
                    }
                }
                break
        }
    }
    return store
}