import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import numeral from 'numeral'
import Switch from 'react-switch'
import Select from 'react-select'

import { modifyInputs } from '../actions'

class Input extends Component {
    onChange = (e) => {
        const { type, reducer, path, multi } = this.props
        let value
        switch (type) {
            case 'checkbox':
                value = e.target.checked
                break
            case 'switch':
                value = e
                break
            case 'autocomplete':
                if (multi) {
                    value = e ? e : null;
                } else {
                    value = e ? e.value : null;
                }
                break
            case 'file':
                value = e ? { value: e.target.value, files: e.target.files } : null
                break
            default:
                value = e.target.value
        }

        this.props.modifyInputs(reducer, path, value);

        if (typeof this.props.afterChange === 'function') {
            this.props.afterChange(value);
        }
    }

    onReset = () => {
        const { reducer, path } = this.props
        this.props.modifyInputs(reducer, path, null)
    }

    render() {
        const { type, options, disabled, label, commentario, maxLength, placeholder, title, style, onKeyDown, format, rows, loadOptions } = this.props
        let { value } = this.props
        const attrs = {
            className: 'form-control input-sm',
            onChange: (e) => this.onChange(e),
            disabled: disabled === undefined ? false : disabled
        }
        if (placeholder) attrs.placeholder = placeholder
        if (title) attrs.title = title
        if (style) attrs.style = style
        if (onKeyDown) attrs.onKeyDown = (e) => onKeyDown(e)
        if (loadOptions) attrs.loadOptions = loadOptions
        switch (type) {
            case 'text':
                if (maxLength) attrs.maxLength = maxLength
                value = (value === null || value === undefined) ? '' : value
                if (format && value) value = numeral(value).format(format)
                return (
                    <input
                        {...attrs}
                        type="text"
                        value={value}
                    />
                )
            case 'date':
                value = (value === null || value === undefined || value === '') ? '' : moment(value).format('YYYY-MM-DD')
                return (
                    <input
                        {...attrs}
                        type="date"
                        value={value}
                    />
                )
            case 'number':
                value = (value === null || value === undefined) ? '' : value
                return (
                    <input
                        {...attrs}
                        type="number"
                        value={value}
                    />
                )
            case 'color':
                return (
                    <input
                        {...attrs}
                        type="color"
                        value={value || ''}
                    />
                )
            case 'textarea':
                if (maxLength) attrs.maxLength = maxLength
                attrs.rows = rows ? rows : 3
                return (
                    <textarea
                        {...attrs}
                        value={value || ''}
                    />
                )
            case 'checkbox':
                return (
                    <input
                        {...attrs}
                        className={undefined}
                        type="checkbox"
                        checked={value || false}
                    />
                )
            case 'switch':
                delete attrs.className
                const tmp = {}
                if (attrs.title) {
                    tmp.title = attrs.title
                    delete attrs.title
                }
                const attrsLabel = {}
                if (this.props.onClick) attrsLabel.onClick = () => this.props.onClick()
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '8px', borderBottom: '0.5px solid #eee' }}>
                        <div style={{ flex: 1, color: '#777' }} {...attrsLabel}>
                            {label}
                            <div style={{ fontSize: '0.7em' }}>{commentario}</div>
                        </div>
                        <div style={{ padding: '8px 0' }}>
                            <span className='switch-wrapper' {...tmp}>
                                <Switch
                                    {...attrs}
                                    checked={value || false}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onColor="#41afbe"
                                    offColor="#ddd"
                                    height={17}
                                    width={30}
                                />
                            </span>
                        </div>
                    </div>
                )
            case 'autocomplete':
                delete attrs.className
                const selectProps = {...this.props, ...attrs}
                let v1 = value || null
                attrs.className = 'autocomplete'

                if (typeof loadOptions === 'function'){
                    return (
                        <Select.Async
                            {...selectProps}
                            value={v1}
                        />
                    )
                }

                return (
                    <Select
                        {...selectProps}
                        value={v1}
                        options={options.map(x => { return { value: x.value, label: x.caption } })}
                    />
                )
            case 'select':
                let v = String(value)
                v = (v === "undefined" || v === "null" || v === "") ? "-1" : v

                return (
                    <select
                        {...attrs}
                        value={v}
                    >
                        <option value={"-1"} disabled>{this.props.placeholder || 'Seleccione...'}</option>
                        {
                            options.map((x, n) => {
                                return (
                                    <option key={`opt[${n}][${x.value}]`} value={String(x.value)}>{x.caption}</option>
                                )
                            })
                        }
                    </select>
                )
            case 'selector':
                value = (value === null || value === undefined) ? '' : value
                return (
                    <div className={`form-control input-sm SelectorInput${disabled ? ' disabled' : ''}`}>
                        <span className="caption">
                            <span>
                                {value}
                            </span>
                        </span>
                        {!disabled && value ? <span className="reset" onClick={(e) => this.onReset(e)}>&#10005;</span> : null}
                        <span className="button">
                            <button disabled={disabled} onClick={(e) => { e.preventDefault(); this.props.onClick(e) }}>...</button>
                        </span>
                    </div>
                )
            case 'file':
                return (
                    <input
                        type={type}
                        value={(value === null || value === undefined) ? '' : value.value}
                        {...attrs} />
                )
            default:
                return (
                    <input
                        type={type}
                        value={(value === null || value === undefined) ? '' : value}
                        {...attrs} />
                )
        }
    }
}

function mapStateToProps(state, onwProps) {
    return {
        value: state[onwProps.reducer].getIn(onwProps.path.split('.'))
    }
}

function mapDispatchToProps(dispatch) {
    return {
        modifyInputs: (reducer, path, value) => dispatch(modifyInputs(reducer, path, value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Input)