/**
 * Crea el elemento contenedor del modal en el body
 */
export const crearContenedorEnBody = (nombre) => {
    let contenedor = document.getElementById(nombre);

    if (!contenedor) {
        contenedor = document.createElement('div');
        contenedor.id = nombre;
        document.body.appendChild(contenedor);
    }
}

/**
 * Elimina el elemento contenedor del modal del body
 */
export const eliminarContenedorDelBody = (nombre) => {
    const contenedor = document.getElementById(nombre);

    if (contenedor) {
        contenedor.remove();
    }
}